//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data: () => ({
    calendarEventColor: "blue",
    calendarEventName: "",
    items: [
      {
        header:
          "Carrer de les flors de Sant Joan, 25 Afores | 08572 Sant Joaquim de Vilatorrada | 661 654 443"
      },
      {
        icon: "mdi-calendar-check",
        iconClass: "green white--text",
        title: "(10:00 | 3:00h) Muntatge llit",
        subtitle: "Notes Revisar si el terra ja esta pulit..."
      },
      { divider: true, inset: true },
      {
        icon: "mdi-calendar",
        iconClass: "red white--text",
        title: "(15:00 | 3:00h) Muntatge armari",
        subtitle: "Revisar les notes del client"
      },
      { divider: true, inset: true },
      {
        icon: "mdi-calendar",
        iconClass: "red white--text",
        title: "(18:00 | 0:05h) Trucar per comentar que ja hem acabat",
        subtitle: ""
      }
    ]
  }),
  created() {
    this.calendarEventColor = sessionStorage.getItem("calendar.event.color");
    this.calendarEventName = sessionStorage.getItem("calendar.event.name");
  },
  methods: {
    goTaskDetail(item) {
      sessionStorage.setItem("EditTask.item.title", item.title);
      this.$router.push("/edittaskdetail");
    },
    goSignature() {
      console.log("/edittasksignature");
      this.$router.push("/edittasksignature");
    },
    goTakePhoto() {
      console.log("/edittasktakephoto");
      this.$router.push("/edittasktakephoto");
    }
  }
};
