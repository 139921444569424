import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"4","md":"8","lg":"12"}},[_c(VCard,{staticClass:"mx-auto",attrs:{"min-width":"350"}},[_c(VToolbar,{attrs:{"color":_vm.calendarEventColor,"dark":""}},[_c(VAppBarNavIcon),_c(VToolbarTitle,[_vm._v(_vm._s(_vm.calendarEventName))]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,[_vm._v("mdi-note-text")])],1),_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,[_vm._v("mdi-map-marker")])],1)],1),_c(VList,{attrs:{"three-line":""}},[_vm._l((_vm.items),function(item,index){return [(item.header)?_c(VSubheader,{key:item.header},[_vm._v(_vm._s(item.header))]):(item.divider)?_c(VDivider,{key:index,attrs:{"inset":item.inset}}):_c(VListItem,{key:item.title,on:{"click":function($event){return _vm.goTaskDetail(item)}}},[_c(VListItemAvatar,[_c(VIcon,{class:[item.iconClass]},[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.title))]),_c(VListItemSubtitle,[_vm._v(_vm._s(item.subtitle))])],1)],1)]})],2)],1)],1)],1),_c(VRow,{attrs:{"justify":"center"}},[_c(VBtn,{staticClass:"mx-2 mt-4",attrs:{"fab":"","dark":"","large":"","color":"primary"},on:{"click":function($event){return _vm.goSignature()}}},[_c(VIcon,{attrs:{"dark":""}},[_vm._v("mdi-draw")])],1),_c(VBtn,{staticClass:"mx-2 mt-4",attrs:{"fab":"","dark":"","large":"","color":"primary"},on:{"click":function($event){return _vm.goTakePhoto()}}},[_c(VIcon,{attrs:{"dark":""}},[_vm._v("mdi-camera")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }